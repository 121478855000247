// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
export const LABEL_SCENARIO_LIKEFORLIKE_AMD64_WITH_BURSTABLE =
  'Closest Like for Like VM specification (amd64, mix burstable and non burstable)';
export const LABEL_SCENARIO_LIKEFORLIKE_AMD64_NON_BURSTABLE =
  'Closest Like for Like VM specification (amd64, non burstable only)';
export const LABEL_SCENARIO_LIKEFORLIKE_AMD64_BURSTABLE_ONLY =
  'Closest Like for Like VM specification (amd64, burstable only)';
export const LABEL_SCENARIO_BINPACK_AMD64_NON_BURSTABLE =
  'Binpack / Closest possible to CPU and Memory utilization (amd64, non burstable)';
export const LABEL_SCENARIO_BINPACK_AMD64_BURSTABLE_ONLY =
  'Binpack / Closest possible to CPU and Memory utilization (amd64, burstable only)';
export const LABEL_SCENARIO_LIKEFORLIKE_GRAVITON_NONMETAL_WITH_BURSTABLE =
  'Closest Like for Like VM specification (AWS Graviton non Bare Metal, mix burstable and non burstable)';
export const LABEL_SCENARIO_BINPACK_GRAVITON_NONMETAL =
  'Binpack / Closest possible to CPU and Memory utilization (AWS Graviton non Bare Metal, non burstable)';
export const LABEL_SCENARIO_VIRTUALIZATION_NONGRAVITON = 'ROSA Virtualization (Amazon EC2 Bare Metal x86)';

const HOURS_PER_MONTH = 730;
export const rosaWorkerFeesOnDemand = 0.171; // 1500/12/HOURS_PER_MONTH
export const rosaWorkerFees1year = 1000 / 12 / HOURS_PER_MONTH;
export const rosaWorkerFees3year = 667 / 12 / HOURS_PER_MONTH;
