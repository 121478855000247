// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useLayoutEffect, useState } from 'react';
import Alert from '@cloudscape-design/components/alert';
import { useLocation } from 'react-router-dom';
import Icon from '@cloudscape-design/components/icon';
import Link from '@cloudscape-design/components/link';

import { getCheaperUnitPricesVariants } from '../../lib/cost';

export function BestPriceSummary(props) {
  const { tableItems, ec2Prices } = props;
  const [items, setItems] = useState();
  const [count, setCount] = useState();

  useLayoutEffect(() => {
    const items = (tableItems || []).filter(item => item.count > 0);
    let count = 0;
    (items || []).map(node => (count += getCheaperUnitPricesVariants(ec2Prices, node.id).length - 1));

    setCount(count);
    setItems(items);
  }, [ec2Prices, tableItems]);

  const location = useLocation();

  const generateInstanceLink = (fromInstance, toInstance) => {
    const searchParams = new URLSearchParams(location.search);
    const nodesParam = searchParams.get('nodes');
    if (nodesParam) {
      const oldNodes = JSON.parse(nodesParam);
      const nodes = oldNodes.map(node => {
        if (node.type === fromInstance) {
          return {
            ...node,
            type: toInstance,
          };
        }
        return node;
      });
      searchParams.set('nodes', JSON.stringify(nodes));
    }
    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    count > 0 && (
      <Alert statusIconAriaLabel="Info" header="Price-performance recommendations">
        <br />
        Instance types suggestions for best price performance or lowest price (be aware that suggestions may propose the
        use of Graviton instead of am64 based instances):
        {(items || []).map(node => (
          <ul key={`${node.id}-a`}>
            {getCheaperUnitPricesVariants(ec2Prices, node.id).length > 1 && (
              <li>
                Alternative(s) to currently selected <strong>{node.id}</strong> (
                {getCheaperUnitPricesVariants(ec2Prices, node.id).slice(-1)[0].priceOnDemand}/Hour -{' '}
                {getCheaperUnitPricesVariants(ec2Prices, node.id).slice(-1)[0].vcpu} vCPUs,{' '}
                {getCheaperUnitPricesVariants(ec2Prices, node.id).slice(-1)[0].memory} Memory) - from lowest to highest
                price:
                <ol>
                  {getCheaperUnitPricesVariants(ec2Prices, node.id)
                    .slice(0, -1)
                    .map(ec2 => (
                      <li key={`${ec2.type}-b`}>
                        {ec2.type} - {ec2.priceOnDemand}/Hour - {ec2.vcpu} vCPUs, {ec2.memory} Memory.{' '}
                        <strong>
                          <Icon name="external" />{' '}
                          <Link
                            href={generateInstanceLink(
                              getCheaperUnitPricesVariants(ec2Prices, node.id).slice(-1)[0].type,
                              ec2.type
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Use this instance instead.
                          </Link>
                        </strong>
                      </li>
                    ))}
                </ol>
              </li>
            )}
          </ul>
        ))}
      </Alert>
    )
  );
}
